@import "src/shapes.scss";
.question-main-container {
  border-radius: 10px;
  background: linear-gradient(
    171deg,
    #cbf3ff -1.43%,
    #fff 13.41%,
    #f7eeff 30.96%
  );
  height: 1916px;
  position: relative;
}
.question-greeting-container {
  padding: 154px 0px 39px 18.6%;
  position: relative;
}
.question-h1 {
  font-family: Dongle;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: linear-gradient(92deg, #2000e5 -5.58%, #0075ff 88.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.question-greeting {
  color: #404040;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-top: 40px;
}
.question-white-container {
  height: 1072px;
  background: linear-gradient(
    278deg,
    rgba(255, 255, 255, 0.95) 82.06%,
    rgba(255, 255, 255, 0) 118.47%
  );
  padding-top: 57px;
  padding-left: 18.6%;
  padding-right: 230px;
  margin-bottom: 150px;
  position: relative;
  z-index: 1;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  label {
    margin-bottom: 8px;
    color: #000;
    font-family: "Along Sans s2";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    b {
      color: #cb0074;
      font-family: "Along Sans s2";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }
  .input {
    width: 400px;
    border-radius: 4px;
    // color: #8e8e8e;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 10px 14px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    border: unset;
  }
  textarea {
    width: 1000px;
    height: 249px;
    padding: 9px 12px;
    resize: none;
    border-radius: 4px;
    border: unset;
    background: #f5f5f5;
    // color: #8e8e8e;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
  height: fit-content;
  width: 400px;
}

.dropdown-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // color: #8e8e8e;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  border-radius: 4px;
  background: white;
  box-shadow:
    0px 0px 2px 0px rgba(0, 0, 0, 0.12),
    0px 8px 16px 0px rgba(0, 0, 0, 0.14);
  ul {
    padding: 4px;
    cursor: pointer;
    li {
      color: black;
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 6px 0px 6px 28px;
      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}

.dropdown-container.open .dropdown-content {
  display: block;
}
.file-upload-container {
  display: flex;
  align-items: center;
  width: 400px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  color: #545454;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  .clip {
    width: 24px;
    margin-right: 5px;
  }
}
.submit-button {
  padding: 12px 16px;
  border-radius: 10px;
  background: #2000e5;
  color: var(--white, #fff);
  font-family: Gilroy;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  width: 229px;
  text-align: center;
  margin-top: 84px;
  cursor: pointer;
}
.cube-in-question {
  position: absolute;
  left: 35%;
  height: 155px;
  @include shapes-spin;
}
.ball-in-question {
  position: absolute;
  @include shapes-spin;
  width: 150px;
  left: 5%;
  bottom: -30%;
}
.spiral-in-question {
  position: absolute;
  @include shapes-spin;
  right: 5%;
  bottom: -20%;
}
.cone-in-question {
  position: absolute;
  @include shapes-spin;
  left: 30%;
  bottom: 20%;
}
.error-msg{
  display: none;
  color: #C50F1F;
  margin-top: 5px;
  display: flex;
  align-items: center;
  img{
    width: 12px;
    margin-right: 6px;
  }
}
@media (max-width: 960px) {
  .question-greeting-container {
    padding: 88px 24px 55px;
  }
  .question-h1 {
    font-size: 45px;
    line-height: 100%;
    position: relative;
  }
  .question-greeting {
    position: relative;
  }
  .question-white-container {
    padding: 21px 24px 54px;
    height: unset;
    margin-bottom: 90px;
  }
  .input-container {
    textarea {
      width: 100%;
      box-sizing: border-box;
    }
    .input {
      width: 100%;
    }
  }
  .dropdown-container {
    width: 100%;
  }
  .file-upload-container {
    width: 100%;
  }
  .cone-in-question {
    width: 127px;
    bottom: 32%;
    right: 10%;
    left: unset;
  }
  .cube-in-question {
    height: 99px;
  }
  .ball-in-question {
    width: 65px;
    bottom: -10%;
  }
  .spiral-in-question {
    height: 80px;
    z-index: 1;
    right: 5%;
    bottom: 34%;
  }
  .submit-button {
    width: 100%;
    margin-top: 0;
  }
}
