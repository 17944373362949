.navagation-container {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
  display: flex;
  margin-top: 36px;
}

.nav-bar {
  background-color: transparent;
  overflow: hidden;
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  z-index: 1;
  align-items: center;
  margin-top: 36px;
  padding: 19px 230px 19px 18.6%;
  justify-content: space-between;
  .header-logo {
    height: 22.5px;
  }
}

.nav-links {
  display: flex;
  justify-content: space-between;
  width: 35%;
  a {
    color: inherit;
    font-size: 17px;
    text-decoration: none;
  }
}

.nav-for-home {
  color: #ffffff;
  li:nth-child(1) {
    color: #fec704;
    font-weight: 700;
  }
}
.nav-for-support {
  color: #005d85;
}
.nav-for-question {
  color: #1400ff;
  li:nth-child(4) {
    color: #fec704;
    font-weight: 700;
  }
}
.nav-for-promo {
  color: #ff0089;
  li:nth-child(2) {
    color: #ff6b00;
    font-weight: 700;
  }
}
.hamburger-menu {
  cursor: pointer;
  display: none;
}
.hamburger-menu-support {
  .bar {
    background-color: #5d5f65;
  }
}
.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: 0.4s;
}
@media screen and (max-width: 960px) {
  .nav-bar {
    margin-top: unset;
    padding: 16px 15px 0px 24px;
  }
  .nav-bar.scrolled {
    padding-top: 0px;
  }
  .hamburger-menu {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
  }

  .nav-links.show {
    display: flex;
  }
}
