@import "src/shapes.scss";
.promo-main-container {
  background: linear-gradient(
    171.3deg,
    #fee2ff -1.43%,
    #ffffff 24.05%,
    #f7eeff 30.96%
  );
  height: 2840px;
}
.torus2-in-promo {
  position: absolute;
  left: 30%;
}
.cube-in-promo {
  position: absolute;
  top: 5%;
  right: 0;
  width: 155px;
}
.promo-first-container {
  padding: 234px 0 0 18.6%;
  position: relative;
  display: flex;
}
.calendar-in-promo {
  position: relative;
  z-index: 1;
}
.balloons-in-promo {
  position: absolute;
  right: 126px;
  top: 120px;
  width: 382px;
  @include shapes-up-down;
}
.hearts-in-promo {
  position: absolute;
  width: 180px;
  left: calc(18.6% - 90px);
  top: 130px;
  @include shapes-spin;
}
.promo-greeting-container {
  position: relative;
  padding-right: 160px;
  h1 {
    font-family: Dongle;
    font-size: 85px;
    font-weight: 400;
    line-height: 85px;
    letter-spacing: 0px;
    background: linear-gradient(92.22deg, #e500dc -5.58%, #eb00ff 88.24%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.spiral-in-promo {
  position: absolute;
  right: 80px;
  top: calc(50% - 70px);
  width: 105px;
  @include shapes-spin;
}
.promo-second-container,
.promo-third-container {
  padding: 100px 160px 0 160px;
  position: relative;
}
.reverse-on-mobile {
  justify-content: space-between;
}
.ball-in-promo {
  position: absolute;
  width: 151px;
  top: -50px;
  @include shapes-up-down;
}
.spiral2-in-promo {
  position: absolute;
  width: 173px;
  right: 180px;
  top: 0;
  @include shapes-up-down;
  z-index: 1;
}
.cube2-in-promo {
  position: absolute;
  width: 247px;
  top: 0;
  left: 35%;
  @include shapes-spin;
}
.promo-white-container {
  background: linear-gradient(
    278.43deg,
    rgba(255, 255, 255, 0.95) 58.59%,
    rgba(255, 255, 255, 0) 118.47%
  );
  display: flex;
  border-radius: 20px;
  padding: 106px;
  position: relative;
}
.gem-in-promo {
  margin-right: 113px;
  width: 457.74px;
  align-self: flex-start
}
.lisa-in-promo {
  margin-right: 30px;
  width: 457.74px;
}
.white-content-container {
  position: relative;
  h3 {
    margin-top: 8px;
    background: linear-gradient(100.11deg, #eb00ff 21.34%, #5700e5 100%);
    font-family: Dongle;
    font-size: 60px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0px;
    text-align: left;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-family: Dongle;
    font-size: 94px;
    font-weight: 400;
    line-height: 85px;
    margin-bottom: 24px;
    letter-spacing: 0px;
    text-align: left;
    background: linear-gradient(100.11deg, #eb00ff 21.34%, #5700e5 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  label {
    font-family: Along Sans s2;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 14px;
  }
  p {
    margin-top: 40px;
    font-family: Along Sans s2;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.firework-in-promo {
  position: absolute;
  width: 120px;
  @include shapes-spin;
  right: calc(30% - 90px);
}
.cones-in-promo {
  position: absolute;
  width: 178px;
  top: 0;
  z-index: 1;
  left: 240px;
  @include shapes-spin;
}
.cone-in-promo {
  position: absolute;
  @include shapes-up-down;
  right: 40px;
  z-index: 1;
  bottom: -135px;
}
.torus1-in-promo {
  left: 0;
  top: 50%;
  position: absolute;
}
.event-div {
  padding: 8px 24px 8px 24px;
  width: 111px;
  background: #ffcaf0;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.4300000071525574px;
  text-align: left;
  color: #fb05b9;
  border-radius: 40px;
  text-align: center;
  margin-top: 33px;
}
.promo-ul {
  padding: 100px 160px 110px 160px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

  // font-family: Along Sans s2;
  // font-size: 16px;
  // font-weight: 400;
  // line-height: 26px;
  // letter-spacing: 0em;
  // text-align: left;

  li {
    display: flex;
  }
  li::before {
    content: "\2022";
    font-size: 0.8em;
    display: inline-block;
    width: 1em;
    margin-right: 0.5em;
  }
}

@media (max-width: 960px) {
  .promo-main-container {
    height: unset;
  }
  .promo-first-container {
    padding: 130px 24px 0;
    flex-direction: column-reverse;
  }
  .promo-greeting-container {
    padding: unset;
    h1 {
      font-size: 45px;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: 0px;
      text-align: left;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.03em;
      margin-top: 10px;
    }
  }
  .calendar-in-promo {
    width: 189px;
    margin: 0 auto;
  }
  .hearts-in-promo,
  .cube-in-promo {
    display: none;
  }
  .promo-second-container,
  .promo-third-container {
    padding: 60px 24px 0px;
  }
  .reverse-on-mobile {
    flex-direction: column-reverse !important;
  }
  .promo-white-container {
    padding: unset;
    flex-direction: column;
    align-items: center;
    padding: 26px;
  }
  .gem-in-promo {
    width: 217px;
    margin-right: unset;
  }
  .lisa-in-promo {
    width: 209px;
    margin-right: unset;
  }
  .white-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-family: Dongle;
      font-size: 40px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;
      width: 100%;
      margin-top: 13px;
    }
    h4 {
      font-family: Dongle;
      font-size: 45px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0px;
      text-align: left;
      width: 100%;
      margin-bottom: unset;
    }
    label {
      font-family: Along Sans s2;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.05em;
      text-align: left;
      width: 100%;
      margin-top: 19px;
    }
    p {
      font-family: Along Sans s2;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      width: 100%;
      margin-top: unset;
    }
  }
  .event-div {
    font-size: 18px;
  }
  .promo-ul {
    padding: 33px 28px 46px;
    font-family: Along Sans s2;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  //
  .balloons-in-promo {
    width: 164px;
    left: calc(50% - 12.5px);
    top: 56px;
    right: 0;
  }
  .spiral-in-promo {
    width: 74px;
    top: -100px;
    left: 0;
  }
  .ball-in-promo {
    width: 100px;
    left: -50px;
    top: 0;
  }
  .cube2-in-promo {
    display: none;
  }
  .spiral2-in-promo {
    width: 90px;
    right: 24px;
    top: 30px;
  }
  .firework-in-promo {
    width: 40px;
    top: 70px;
    right: 5%;
  }
  .cone-in-promo {
    width: 150px;
    right: 0;
    bottom: -30px;
  }
  .cones-in-promo {
    width: 98px;
    left: 34px;
  }
  .torus2-in-promo {
    right: 15%;
    left: unset;
    width: 114px;
  }
  .torus1-in-promo {
    width: 60px;
  }
  //
}
