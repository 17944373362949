.button-row {
  display: flex;
  gap: 24px;
  cursor: pointer;
}

.button-box {
  display: flex;
  width: 229px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--white, #fff);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.43px;
  border-radius: 10px;
}

.button-icon {
  height: 24px;
}

.home-button {
  background: #ffa900;
}

.promo-button {
  background: #ff00c7;
}

@media (max-width: 480px) {
  .button-row {
    flex-direction: column;
    .button-box {
      width: 100%;
    }
  }
}
