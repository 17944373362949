.overlay-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background: #e2d7ff;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 10;

  &.open {
    width: 100%;
  }
}

.overlay-menu ul {
  .bar {
    background-color: #870eff;
  }
  .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .bar:nth-child(2) {
    opacity: 0;
  }

  .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  li:nth-child(1) {
    display: flex;
    flex-direction: row-reverse;
  }

  li {
    padding: 15px 24px;
    text-align: center;
    font-size: 1.2rem;
    cursor: pointer;
    transition: 0.3s;
    text-align: left;
    a {
      text-decoration: none;
    }
  }
}
.home-overlay-menu {
  li:nth-child(2) a {
    color: #ff7a00;
    font-weight: 700;
  }
}
.promo-overlay-menu {
  li:nth-child(3) a {
    color: #ff7a00;
    font-weight: 700;
  }
}
