@import "src/shapes.scss";
.about-main-container {
  background: linear-gradient(
    122deg,
    #c2f5df -5.35%,
    #dffeff 25.48%,
    #dafaff 53.02%,
    #dffbff 73.58%
  );
  width: 100%;
  position: relative;
}
.ball-in-about {
  position: absolute;
  right: 7%;
  top: 4%;
  @include shapes-spin;
}
.about-greeting-container {
  padding: 154px 0px 39px 18.6%;
  position: relative;
}
.about-h1 {
  font-family: Dongle;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: linear-gradient(92deg, #008c51 -5.53%, #0062d5 35.48%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-greeting {
  color: #404040;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-top: 40px;
}
.about-white-container {
  height: 560px;
  background: linear-gradient(
    278deg,
    rgba(255, 255, 255, 0.95) 82.06%,
    rgba(255, 255, 255, 0) 118.47%
  );
  padding-top: 57px;
  padding-left: 18.6%;
  padding-right: 230px;
  margin-bottom: 150px;
  position: relative;
  z-index: 1;
  h3 {
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
  }
  p {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }
}
.cube-in-about {
  position: absolute;
  bottom: -125px;
  left: 5%;
  @include shapes-spin;
}

@media (max-width: 960px) {
  .about-main-container {
    height: unset;
  }
  .ball-in-about,
  .spiral-in-about {
    display: none;
  }

  .about-greeting-container {
    padding: 88px 24px 55px;
  }
  .cube-in-about {
    width: 150px;
    left: -20px;
    bottom: unset;
  }
  .about-h1 {
    font-size: 45px;
    line-height: 100%;
  }
  .about-greeting {
    font-size: 16px;
    font-style: normal;
    line-height: 160%;
  }
  .about-white-container {
    padding: 21px 24px 54px;
    flex-direction: column-reverse;
    height: unset;
    margin-bottom: 69px;
  }
}
