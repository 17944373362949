@import "src/shapes.scss";
.review-section-container {
  padding: 92px 205px 178px 18.6%;
  position: relative;
  .review-section-title {
    color: #fff;
    font-family: Dongle;
    font-size: 78px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
  }
  .reviews-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    .review-container {
      z-index: 1;
      margin-top: 32px;
    }
  }
}
.review-section {
  position: relative;
}
.disc-in-review {
  position: absolute;
  top: -4%;
  left: 12%;
  @include shapes-spin;
}
.pyramid-in-review {
  position: absolute;
  left: -10px;
  @include shapes-spin;
}
.ball-in-review {
  @include shapes-spin;
  position: absolute;
  right: 15%;
}
.vase-in-review {
  position: absolute;
  right: 24%;
  bottom: -15%;
}

@media (max-width: 960px) {
  .review-section-container {
    padding: 24px 24px 60px 24px;
    .review-section-title {
      font-size: 36px;
      line-height: 96px;
    }
    .reviews-container {
      .review-container {
        margin-top: 24px;
      }
      .review-container:nth-child(1) {
        margin-top: unset;
      }
    }
  }
  .disc-in-review,
  .ball-in-review {
    display: none;
  }
  .pyramid-in-review {
    left: -10px;
    top: -20px;
    width: 19%;
  }
}
