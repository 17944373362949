.home-second-secion {
  background: rgba(255, 255, 255, 0.95);
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}
.people-card-section {
  padding: 91px 206px 162px 18.6%;
  .people-card-section-title {
    font-family: Dongle;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    background: linear-gradient(93deg, #4915f7 12.49%, #8f00ff 81.93%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .people-cards-row {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
}
.platonic-in-people {
  position: absolute;
  left: 30%;
  top: -110px;
  transition: all 0.3s ease 0s;
  animation: 5s ease 0s infinite normal none running spin;
}

@media (max-width: 1390px) {
  .people-card {
    min-width: 40%;
  }
}

@media (max-width: 960px) {
  .people-card-section {
    padding: 65px 24px 40px;
    .people-card-section-title {
      font-size: 36px;
      line-height: 100%;
      letter-spacing: 0.48px;
    }
    .people-cards-row {
      display: block;
      .people-card {
        margin: 24px auto;
      }
    }
  }
  .platonic-in-people {
    left: 0;
  }
}
