.icon-card-container {
  width: 32%;
  max-width: 320px;
  min-height: 280px;
  flex-shrink: 0;
  border-radius: 20px;
  background: linear-gradient(
    28deg,
    rgba(255, 255, 255, 0.5) 32.98%,
    rgba(255, 255, 255, 0) 124.03%
  );
  padding: 80px 40px 10px 40px;
  position: relative;
  .icon-box {
    width: 120px;
    height: 120px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -60px;
    left: calc(50% - 60px);
    .icon {
      position: absolute;
    }
    .map-icon {
      left: -8px;
      top: -16px;
      width: 128px;
      height: 130px;
    }
    .books-icon {
      width: 103.067px;
      height: 105px;
      left: 8px;
      top: 7px;
    }
    .password-icon {
      width: 67px;
      height: 74px;
      left: 25px;
      bottom: 20px;
    }
  }
  .card-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.56px;
    text-align: center;
    margin-bottom: 28px;
    background: linear-gradient(228deg, #7700ed -16.11%, #0400dc 95.13%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .card-content {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.6px;
  }
}

@media (max-width: 960px) {
  .icon-card-container {
    min-height: 230px;
    padding: 60px 20px 10px 20px;
    .icon-box {
      width: 90px;
      height: 90px;
      top: -45px;
      left: calc(50% - 45px);
      .map-icon {
        width: 78px;
        height: 79.5px;
        left: 3px;
        top: -3px;
      }
      .books-icon {
        width: 65px;
        height: 66.25px;
        left: 10px;
        top: 11px;
      }
      .password-icon {
        width: unset;
        height: 60px;
        right: 18px;
        top: 14px;
        left: unset;
        bottom: unset;
      }
    }
    .card-title {
      font-size: 20px;
    }
    .card-content {
      font-size: 16px;
    }
  }
}
