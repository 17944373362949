.footer-container {
  display: flex;
  justify-content: space-between;
}
.footer-section {
  padding: 90px 280px 100px 320px;
  border-radius: 0px 0px 12px 12px;
  background: #000508;
  position: relative;
  z-index: 1;
}

.blue-logo {
  width: 38px;
  height: 28px;
  margin-bottom: 8px;
}
.managed-by {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.3px;
  margin-bottom: 25px;
}
.social-medias {
  display: flex;
  gap: 16px;
  margin-bottom: 15px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
}
.copyright {
  color: #848895;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.3px;
  margin-bottom: 20px;
}
.footer-right-container {
  display: flex;
  justify-content: space-between;
}
.footer-menu-column {
  padding: 0px 28px 31px 28px;
  h5 {
    color: #fff;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.72px;
    text-transform: uppercase;
  }
  h6 {
    color: #848895;
    font-family: Helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.3px;
    margin-top: 11px;
    a{
      text-decoration: unset;
      color: inherit;
    }
  }
}
.langurage-icon {
  width: 19px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 8.5px;
}
.langurage-line {
  display: flex;
}
.footer-langurage-menu {
  cursor: pointer;
}
.current-langurage {
  color: #818594;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}
.show {
  visibility: unset;
}
.hide {
  visibility: hidden;
}
.dropdown-options {
  width: 151px;
  background-color: #424242;
  border-radius: 4px;
  padding: 4px;
  margin-top: 20px;
  div {
    padding-left: 6px;
    height: 32px;
    color: #d4d4d5;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

@media (max-width: 960px) {
  .footer-section {
    padding: 24px;
  }
  .footer-container {
    display: unset;
  }
  .footer-right-container {
    display: unset;
  }
  .footer-menu-column {
    padding-left: 0px;
  }
}
